import { types } from "mobx-state-tree"

const ModalState = types.model('ModalState', {
    visibility: false,
}).actions(self => ({

    setVisibility(value) {
        self.visibility = value
    },

    open() {
        self.visibility = true
    },

    close() {
        self.visibility = false
    },

}))

export default ModalState
