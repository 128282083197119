import { types } from "mobx-state-tree"
import MikaConfig from './MikaConfig'
import RallySafeConfig from './RallySafeConfig'
import UltimateConfig from './UltimateConfig'
import WeatherApi from './WeatherApiConfig'

const EventConfig = types.model('EventConfig', {
    mika: types.optional(MikaConfig, () => MikaConfig.create()),
    rally_safe: types.optional(RallySafeConfig, () => RallySafeConfig.create()),
    ultimate: types.optional(UltimateConfig, () => UltimateConfig.create()),
    weather_api: types.optional(WeatherApi, () => WeatherApi.create()),
}).actions(self => ({

    //

})).views(self => ({

    get supportsMika() {
        return !!self.mika
    },

    get supportsRallySafe() {
        return !!self.rally_safe
    },

    get supportsUltimate() {
        return !!self.ultimate
    },

    get supportsWeatherApi() {
        return !!self.weather_api
    },

    get serialized() {
        return {
            mika: self.mika?.serialized,
            rally_safe: self.rally_safe?.serialized,
            ultimate: self.ultimate?.serialized,
            weather_api: self.weather_api?.serialized,
        }
    },

    get isValid() {
        return true
    },

}))

export default EventConfig
