import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'
import MikaSplitTime from './MikaSplitTime'
import MikaNationality from './MikaNationality'

export const MIKA_LEADERBOARD_ENDPOINT = 'mika_leaderboard'

export const GENDER_MALE = { key: 'male', value: 'Male' }
export const GENDER_FEMALE = { key: 'female', value: 'Female' }
export const GENDERS = {
    [GENDER_MALE.key]: GENDER_MALE,
    [GENDER_FEMALE.key]: GENDER_FEMALE,
}

const MikaLeaderboardEndpoint = types.model('MikaLeaderboardEndpoint', {
    type: types.optional(types.literal(MIKA_LEADERBOARD_ENDPOINT), MIKA_LEADERBOARD_ENDPOINT),
    page: types.optional(types.integer, 1),
    page_size: types.optional(types.integer, 10),
    pin_first: false,
    split_time_id: types.maybe(types.reference(MikaSplitTime)),
    nationality_id: types.maybe(types.reference(MikaNationality)),
    gender: types.maybe(types.enumeration(Object.keys(GENDERS))),
}).actions(self => ({

    setPage(value) {
        self.page = value
    },

    setPageSize(value) {
        self.page_size = value
    },

    setPinFirst(value) {
        self.pin_first = value
    },

    setSplitTimeId(value) {
        self.split_time_id = value
    },

    setNationalityId(value) {
        self.nationality_id = value
    },

    setGender(value) {
        self.gender = value
    },

})).views(self => ({

    get baseSerialized() {
        return {
            page: self.page,
            page_size: self.page_size,
            pin_first: self.pin_first,
            split_time_id: self.split_time_id?.id,
            nationality_id: self.nationality_id?.id,
            gender: self.gender,
        }
    },

    get baseIsValid() {
        return !!self.page && !!self.page_size && !!self.split_time_id
    },

}))

export default types.compose(Endpoint, MikaLeaderboardEndpoint)
