
function Checkbox(props) {
    const { name, label, value, onChange, className } = props

    return <div className={`w-full mb-6 ${className}`}>
        <div className='flex items-center'>
            <input
                onChange={e => {
                    onChange(e.target.checked)
                }}
                checked={value}
                id={name}
                type='checkbox'
                className='w-4 h-4 text-blue-600 rounded focus:ring-0 border-gray-600'
            />

            <label htmlFor={name} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 select-none">{label}</label>
        </div>
    </div>
}

export default Checkbox
