import { observer } from 'mobx-react-lite'
import { values } from 'mobx'
import Select from '../../Select'
import { useStore } from '../../../state/RootStore'
import { MODES, TIME_MODES } from '../../../state/models/RallySafe/RallySafeScoreboardEndpoint'
import { listWithNoneOption, parseSelectedInt } from '../../../utils'
import Hr from '../../Hr'
import PageControl from './shared/PageControl'
import PinFirst from './shared/PinFirst'

function RallySafeScoreboardEndpointForm({ endpoint, onSubmit }) {
    const rootStore = useStore()
    const { entityStore } = rootStore

    return <div>
        <Select
            label='Rally'
            name='rally'
            options={listWithNoneOption(values(entityStore.rallySafeRallies).map(rally => ({
                key: rally.id,
                value: rally.name,
            })))}
            value={endpoint.rally_id?.id}
            onSubmit={onSubmit}
            onChange={value => {
                endpoint.setRallyId(parseSelectedInt(value))
            }}
        />


        <PageControl endpoint={endpoint} onSubmit={onSubmit} />
        <PinFirst endpoint={endpoint} />

        <Hr />

        <div className='flex gap-4 mt-4'>

            <Select
                label='Time mode'
                name='time_mode'
                className='flex-1'
                options={Object.values(TIME_MODES)}
                value={endpoint.time_mode}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setTimeMode(value)
                }}
            />

            <Select
                label='Mode'
                name='mode'
                className='flex-1'
                options={Object.values(MODES)}
                value={endpoint.mode}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setMode(value)
                }}
            />
        </div>

        <div className='flex gap-4 mt-4'>
            <Select
                label='Stage'
                name='stage'
                className='flex-1'
                options={listWithNoneOption(values(entityStore.rallySafeStages).map(stage => ({
                    key: stage.id,
                    value: stage.name,
                })))}
                value={endpoint.stage_id?.id}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setStageId(parseSelectedInt(value))
                }}
            />

            <Select
                label='Class'
                name='class'
                className='flex-1'
                options={listWithNoneOption(values(entityStore.rallySafeClasses).map(_class => ({
                    key: _class.id,
                    value: _class.name,
                })))}
                value={endpoint.class_id?.id}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setClassId(parseSelectedInt(value))
                }}
            />
        </div>
    </div>
}

export default observer(RallySafeScoreboardEndpointForm)
