import { types } from "mobx-state-tree"
import EventConfig from './Config/EventConfig'


const Event = types.model('Event', {
    id: types.identifierNumber,
    name: types.string,
    config: types.optional(EventConfig, () => EventConfig.create()),
}).actions(self => ({

    setName(value) {
        self.name = value
    }

})).views(self => ({

    get serialized() {
        return {
            id: self.id,
            name: self.name,
            config: self.config.serialized,
        }
    },

    get isValid() {
        return !!self.name && self.config.isValid
    },

    /*get supportedSources() {
        const sources = {}

        if (self.config.mika.isValid) {
            sources.mika_profile = { title: 'Profile', source: 'Mika', endpointType: 'mika_profile' }
            sources.mika_leaderboard = { title: 'Leaderborad', source: 'Mika', endpointType: 'mika_leaderboard' }
        }

        if (self.config.ultimate.isValid) {
            sources.ultimate_profile = { title: 'Profile', source: 'Ultimate', endpointType: 'ultimate_profile' }
            sources.ultimate_leaderboard = { title: 'Leaderborad', source: 'Ultimate', endpointType: 'ultimate_leaderboard' }
        }

        if (self.config.rally_safe.isValid) {
            sources.rally_safe_profile = { title: 'Profile', source: 'Rally Safe', endpointType: 'rally_safe_profile' }
            sources.rally_safe_scoreboard = { title: 'Scoreboard', source: 'Rally Safe', endpointType: 'rally_safe_scoreboard' }
        }

        if (self.config.weather_api.isValid) {
            sources.weather_api = { title: 'Weather', source: 'Weather API', endpointType: 'weather_api' }
        }

        return sources
    },*/

}))

export default Event
