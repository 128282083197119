import { types } from 'mobx-state-tree'
import Event from './Event'

const Endpoint = types.model('Endpoint', {
    id: types.optional(types.identifierNumber, 0),
    event_id: types.maybe(types.reference(Event)),
    slug: types.maybe(types.string),
}).actions(self => ({

    setSlug(value) {
        self.slug = value
    },

})).views(self => ({

    get baseSerialized() {
        return {}
    },

    get serialized() {
        const data = {
            event_id: self.event_id?.id,
            slug: self.slug,
            type: self.type,
            ...self.baseSerialized,
        }

        if (self.id > -1) {
            data.id = self.id
        }

        return data
    },

    get baseIsValid() {
        return true
    },

    get isValid() {
        return self.baseIsValid && !!self.slug
    }

}))

export default Endpoint
