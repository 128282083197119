import { types } from "mobx-state-tree"

const RallySafeConfig = types.model('RallySafeConfig', {
    configured: false,
    event_id: types.optional(types.string, ''),
    itinerary_id: types.optional(types.string, ''),
    username: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
}).actions(self => ({

    setEventId(value) {
        self.event_id = value
    },

    setItineraryId(value) {
        self.itinerary_id = value
    },

    setUsername(value) {
        self.username = value
    },

    setPassword(value) {
        self.password = value
    },

})).views(self => ({

    get serialized() {
        if (!self.isValid) {
            return undefined
        }

        return {
            event_id: self.event_id,
            itinerary_id: self.itinerary_id,
            username: self.username,
            password: self.password,
        }
    },

    get isValid() {
        return !!self.event_id && !!self.username && !!self.password
    },

}))

export default RallySafeConfig
