import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'

export const WEATHER_API_ENDPOINT = 'weather_api'

const WeatherApiEndpoint = types.model('WeatherApiEndpoint', {
    type: types.optional(types.literal(WEATHER_API_ENDPOINT), () => WEATHER_API_ENDPOINT),
    search_query: types.optional(types.string, ''),
}).actions(self => ({

    setSearchQuery(value) {
        self.search_query = value
    },

})).views(self => ({

    get baseSerialized() {
        return {
            search_query: self.search_query,
        }
    },

    get baseIsValid() {
        return !!self.search_query
    },

}))

export default types.compose(Endpoint, WeatherApiEndpoint)
