import { observer } from 'mobx-react-lite'
import Checkbox from '../../../Checkbox'

function PinFirst({ endpoint }) {
    return <div>
        <Checkbox
            name='pin_first'
            label='Pin first entry'
            value={endpoint.pin_first}
            onChange={value => {
                endpoint.setPinFirst(value)
            }}
            className=''
        />
    </div>
}

export default observer(PinFirst)
