import { observer } from 'mobx-react-lite'
import Input from '../../Input'

function WeatherApiEndpointForm({ endpoint }) {
    return <div>
        <Input
            name='search_query'
            label='Search query'
            placeholder='A city, an address, a country, etc'
            className='mb-10'
            value={endpoint.search_query}
            onChange={e => {
                endpoint.setSearchQuery(e.target.value)
            }}
        />
    </div>
}

export default observer(WeatherApiEndpointForm)
