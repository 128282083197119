import {observer} from 'mobx-react-lite'
import Input from '../../../Input'
import Button, {BUTTON_SIZE_INPUT, BUTTON_STYLE_NORMAL} from '../../../Button'

function PageControl({ endpoint, onSubmit }) {
    return <div className='flex gap-8'>
        <div className='flex flex-1 mb-6 gap-2'>
            <Input
                name='page'
                label='Page'
                placeholder='Page'
                className='!mb-0 flex-1'
                value={endpoint.page || 0}
                onSubmit={onSubmit}
                onChange={e => {
                    endpoint.setPage(parseInt(e.target.value) || 1)
                }}
            />

            <div className='flex flex-col justify-end'>
                <Button
                    onClick={(e) => {
                        if (endpoint.page > 1) {
                            endpoint.setPage(endpoint.page - 1)
                        }
                    }}
                    size={BUTTON_SIZE_INPUT}
                    style={BUTTON_STYLE_NORMAL}
                    onSubmit={onSubmit}
                >-</Button>
            </div>

            <div className='flex flex-col justify-end'>
                <Button
                    onClick={(e) => {
                        endpoint.setPage(endpoint.page + 1)
                    }}
                    size={BUTTON_SIZE_INPUT}
                    style={BUTTON_STYLE_NORMAL}
                    onSubmit={onSubmit}
                >+</Button>
            </div>
        </div>

        <Input
            name='page_size'
            label='Page size'
            placeholder='Page size'
            className='!mb-0 flex-1'
            value={endpoint.page_size || 0}
            onSubmit={onSubmit}
            onChange={e => {
                endpoint.setPageSize(parseInt(e.target.value) || 1)
            }}
        />
    </div>
}

export default observer(PageControl)
