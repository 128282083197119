import { observer } from 'mobx-react-lite'
import { values } from 'mobx'
import Select from '../../Select'
import { useStore } from '../../../state/RootStore'
import { listWithNoneOption, parseSelectedInt, parseSelectedString } from '../../../utils'
import Hr from '../../Hr'
import { GENDERS } from '../../../state/models/Ultimate/UltimateLeaderboardEndpoint'
import PageControl from './shared/PageControl'
import PinFirst from './shared/PinFirst'
import Checkbox from '../../Checkbox'

function UltimateLeaderboardEndpointForm({ endpoint, onSubmit }) {
    const rootStore = useStore()
    const { entityStore } = rootStore

    return <div>
        <PageControl endpoint={endpoint} onSubmit={onSubmit} />
        <PinFirst endpoint={endpoint} />

        <Hr spacing />

        <Select
            label='Split'
            name='split'
            className='flex-1'
            options={listWithNoneOption(values(entityStore.ultimateSplitTimes).map(_class => ({
                key: _class.id,
                value: _class.name,
            })))}
            value={endpoint.split_time_id?.id}
            onSubmit={onSubmit}
            onChange={value => {
                endpoint.setSplitTimeId(parseSelectedInt(value))
            }}
        />

        <div className='flex gap-4 mt-4'>
            <Select
                label='Nationality'
                name='nationality'
                className='flex-1'
                options={listWithNoneOption(values(entityStore.ultimateNationalities).map(nationality => ({
                    key: nationality.id,
                    value: nationality.name,
                })), 'All')}
                value={endpoint.nationality_id?.id}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setNationalityId(parseSelectedInt(value))
                }}
            />

            <Select
                label='Gender'
                name='gender'
                className='flex-1'
                options={listWithNoneOption(Object.values(GENDERS).map(stage => ({
                    key: stage.key,
                    value: stage.value,
                })), 'All')}
                value={endpoint.gender}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setGender(parseSelectedString(value))
                }}
            />
        </div>

        <div className='flex gap-4'>
            <Select
                label='Category'
                name='category'
                className='flex-1'
                options={listWithNoneOption(values(entityStore.ultimateCategories).map(category => ({
                    key: category.id,
                    value: category.name,
                })), 'All')}
                value={endpoint.category_id?.id}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setCategoryId(parseSelectedInt(value))
                }}
            />

            <Checkbox
                name='dm'
                className='flex-1 mt-8 mx-auto flex justify-center'
                label='DM'
                value={endpoint.dm_filter}
                onChange={value => {
                    endpoint.setDmFilter(value)
                }}
            />
        </div>

    </div>
}

export default observer(UltimateLeaderboardEndpointForm)
