import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'

export const ULTIMATE_EVENT_ENDPOINT = 'ultimate_event'

export const PASSING_POINT_CHECKIN = { key: 'CheckIn', value: 'CheckIn' }
export const PASSING_POINT_START = { key: 'Start', value: 'Start' }
export const PASSING_POINT_FINISH = { key: 'Finish', value: 'Finish' }
export const PASSING_POINTS = {
    [PASSING_POINT_CHECKIN.key]: PASSING_POINT_CHECKIN,
    [PASSING_POINT_START.key]: PASSING_POINT_START,
    [PASSING_POINT_FINISH.key]: PASSING_POINT_FINISH,
}

const UltimateEventEndpoint = types.model('UltimateEventEndpoint', {
    type: types.optional(types.literal(ULTIMATE_EVENT_ENDPOINT), ULTIMATE_EVENT_ENDPOINT),
    passing_point: types.optional(types.enumeration(Object.keys(PASSING_POINTS)), PASSING_POINT_FINISH.key),
    //event_starts_at: types.optional(types.string, ''),
}).actions(self => ({

    setPassingPoint(value) {
        self.passing_point = value
    },

    /*setEventStartsAt(value) {
        self.event_starts_at = value
    },*/

})).views(self => ({

    get baseSerialized() {
        return {
            passing_point: self.passing_point,
            //event_starts_at: self.event_starts_at,
        }
    },

    get baseIsValid() {
        return !!self.passing_point //&& !!self.event_starts_at
    },

}))

export default types.compose(Endpoint, UltimateEventEndpoint)
