import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'
import UltimateSplitTime from './UltimateSplitTime'
import UltimateNationality from './UltimateNationality'
import UltimateCategory from './UltimateCategory'

export const ULTIMATE_LEADERBOARD_ENDPOINT = 'ultimate_leaderboard'

export const GENDER_MALE = { key: 'male', value: 'Male' }
export const GENDER_FEMALE = { key: 'female', value: 'Female' }
export const GENDERS = {
    [GENDER_MALE.key]: GENDER_MALE,
    [GENDER_FEMALE.key]: GENDER_FEMALE,
}

const UltimateLeaderboardEndpoint = types.model('UltimateLeaderboardEndpoint', {
    type: types.optional(types.literal(ULTIMATE_LEADERBOARD_ENDPOINT), ULTIMATE_LEADERBOARD_ENDPOINT),
    page: types.optional(types.integer, 1),
    page_size: types.optional(types.integer, 10),
    pin_first: false,
    split_time_id: types.maybe(types.reference(UltimateSplitTime)),
    nationality_id: types.maybe(types.reference(UltimateNationality)),
    category_id: types.maybe(types.reference(UltimateCategory)),
    gender: types.maybe(types.enumeration(Object.keys(GENDERS))),
    dm_filter: false,
}).actions(self => ({

    setPage(value) {
        self.page = value
    },

    setPageSize(value) {
        self.page_size = value
    },

    setPinFirst(value) {
        self.pin_first = value
    },

    setSplitTimeId(value) {
        self.split_time_id = value
    },

    setNationalityId(value) {
        self.nationality_id = value
    },

    setCategoryId(value) {
        self.category_id = value
    },

    setGender(value) {
        self.gender = value
    },

    setDmFilter(value) {
        self.dm_filter = value
    },

})).views(self => ({

    get baseSerialized() {
        return {
            page: self.page,
            page_size: self.page_size,
            pin_first: self.pin_first,
            split_time_id: self.split_time_id?.id,
            nationality_id: self.nationality_id?.id,
            category_id: self.category_id?.id,
            gender: self.gender,
            dm_filter: self.dm_filter,
        }
    },

    get baseIsValid() {
        return !!self.page && !!self.page_size && !!self.split_time_id
    },

}))

export default types.compose(Endpoint, UltimateLeaderboardEndpoint)
