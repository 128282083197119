
export const BUTTON_SIZE_SMALL = 'small'
export const BUTTON_SIZE_MEDIUM = 'medium'
export const BUTTON_SIZE_BIG = 'big'
export const BUTTON_SIZE_INPUT = 'input'

export const BUTTON_STYLE_NORMAL = 'normal'
export const BUTTON_STYLE_SAVE = 'save'
export const BUTTON_STYLE_DELETE = 'delete'

function Button({ onClick, onSubmit, style = BUTTON_STYLE_NORMAL, size = BUTTON_SIZE_BIG, children }) {

    const classes = () => {
        if (style === BUTTON_STYLE_NORMAL) {
            return 'text-expo-white-medium hover:text-expo-white border-expo-thin hover:border-expo-white-medium hover:bg-expo-light'
        } else if (style === BUTTON_STYLE_SAVE) {
            return 'text-blue-400 border-expo-thin hover:border-blue-500 hover:text-blue-500'
        } else if (style === BUTTON_STYLE_DELETE) {
            return 'text-red-400 hover:text-red-500 text-red-400 border-expo-thin hover:border-red-500'
        }

        return ''
    }

    const sizing = () => {
        if (size === BUTTON_SIZE_SMALL) {
            return 'px-3 py-2 text-xs'
        } else if (size === BUTTON_SIZE_MEDIUM) {
            return 'px-3 py-3 text-xs'
        } else if (size === BUTTON_SIZE_BIG) {
            return 'px-5 py-2.5 mb-2 text-sm'
        } else if (size === BUTTON_SIZE_INPUT) {
            return 'h-[42px] w-[42px] text-sm'
        }

        return ''
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

    return <button
        type='button'
        className={`${classes()} ${sizing()} select-none border focus:outline-none font-medium rounded-lg text-sm bg-expo-medium`}
        onClick={onClick}
        onKeyPress={handleKeyPress}
    >
        {children}
    </button>
}

export default Button
