import { observer } from 'mobx-react-lite'
import Input from '../../Input'
import Checkbox from '../../Checkbox'

function MikaProfileEndpointForm({ endpoint, onSubmit }) {

    return <div>
        <Checkbox
            name='use_participant_ids'
            label='Use participant ids'
            value={!!endpoint.participant_ids}
            onChange={() => {
                if (endpoint.participant_ids) {
                    endpoint.enableStartNumbers()
                } else {
                    endpoint.enableParticipantIds()
                }
            }}
        />

        <div>
            {endpoint.start_numbers && <Input
                name='start_number'
                label='Start number'
                placeholder='Start number'
                className='mb-10 flex-1'
                value={endpoint.shownStartNumbers}
                onSubmit={onSubmit}
                onChange={e => {
                    endpoint.setStartNumbersFromString(e.target.value)
                }}
            />}

            {endpoint.participant_ids && <Input
                name='participant_ids'
                label='Participant IDs'
                placeholder='Participant IDs'
                className='mb-10 flex-1'
                value={endpoint.shownParticipantIds}
                onSubmit={onSubmit}
                onChange={e => {
                    endpoint.setParticipantIdsFromString(e.target.value)
                }}
            />}
        </div>

    </div>
}

export default observer(MikaProfileEndpointForm)
