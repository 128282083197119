import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

function Modal(props) {
    const [disableClose, setDisableClose] = useState(false)
    const { modalState, onClose, children } = props
    const { visibility, setVisibility } = modalState

    const close = () => {
        setVisibility(false)

        if (onClose) {
            onClose()
        }
    }

    return <>
        {visibility ? (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    onClick={(e) => {
                        if (!disableClose) {
                            close()
                        }
                    }}
                    onMouseUp={() => {
                        setTimeout(() => {
                            setDisableClose(false)
                        }, 100)
                    }}
                >
                    <div
                        className="relative w-auto my-6 mx-auto max-w-3xl max-h-full"
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                         onMouseDown={() => {
                             setDisableClose(true)
                         }}
                         onMouseUp={() => {
                             setDisableClose(false)
                         }}
                    >
                        <div className='shadow-lg relative flex flex-col w-full outline-none focus:outline-none border border-expo-medium rounded-lg bg-expo-medium' style={{
                            width: 550,
                        }}>
                            {children}
                        </div>
                    </div>
                </div>
                <div className="opacity-80 fixed inset-0 z-40 bg-black" />
            </>
        ) : null}
    </>
}

export default observer(Modal)
