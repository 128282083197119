import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'
import { MODES, TIME_MODES } from '../../../state/models/RallySafe/RallySafeScoreboardEndpoint'

function RallySafeScoreboardEndpointCard({ endpoint }) {
    return <>
        <Line label='Class'>
            <Label>{endpoint.class_id?.name || '-'}</Label>
        </Line>

        <Line label='Page'>
            <Label>{endpoint.page}</Label>
        </Line>

        <Line label='Page size'>
            <Label>{endpoint.page_size}</Label>
        </Line>

        <Line label='Rally'>
            <Label>{endpoint.rally_id?.name || '-'}</Label>
        </Line>

        <Line label='Stage'>
            <Label>{endpoint.stage_id?.name || '-'}</Label>
        </Line>

        <Line label='Time'>
            <Label>{TIME_MODES[endpoint.time_mode].value}</Label>
        </Line>

        <Line label='Mode'>
            <Label>{MODES[endpoint.mode].value}</Label>
        </Line>

        <Line label='Pin first entry'>
            <Label>{endpoint.pin_first ? 'Yes' : 'No'}</Label>
        </Line>
    </>
}

export default observer(RallySafeScoreboardEndpointCard)
