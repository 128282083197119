import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'

export const ULTIMATE_PROFILE_ENDPOINT = 'ultimate_profile'

const UltimateProfileEndpoint = types.model('UltimateProfileEndpoint', {
    type: types.optional(types.literal(ULTIMATE_PROFILE_ENDPOINT), ULTIMATE_PROFILE_ENDPOINT),
    start_numbers: types.optional(types.array(types.string), []),
    show_estimated: false,
}).actions(self => ({

    setStartNumbersFromString(value) {
        self.start_numbers = value.split(',')
    },

    setShowEstimated(value) {
        self.show_estimated = value
    },

})).views(self => ({

    get baseSerialized() {
        return {
            start_numbers: [...self.start_numbers],
            show_estimated: self.show_estimated,
        }
    },

    get baseIsValid() {
        return self.start_numbers.length > 0
    },

    get shownStartNumbers() {
        return self.start_numbers.join(',')
    },

}))

export default types.compose(Endpoint, UltimateProfileEndpoint)
