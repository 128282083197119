
function Select(props) {
    const { name, label, options = [], value, onChange, className, onSubmit } = props

    const handleKeyPress = event => {
        if (event.key === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

    return <div className={`w-full mb-6 ${className}`}>
        {label && <label htmlFor={name} className="block mb-2 text-sm font-medium text-expo-white">{label}</label>}
        <select
            id={name}
            className="bg-expo-medium hover:bg-expo-light border border-expo-thin text-expo-white text-sm rounded-lg block w-full p-2.5 cursor-pointer focus:ring-0 focus:outline-none focus:border-expo-white-medium"
            onChange={v => {
                onChange(v.target.value)
            }}
            value={value}
            onKeyPress={handleKeyPress}
        >
            {options.map(option => {
                return <option key={option.key} value={option.key}>{option.value}</option>
            })}
        </select>
    </div>
}

export default Select
