import { types } from "mobx-state-tree"

const RallySafeEntry = types.model('RallySafeEntry', {
    id: types.identifierNumber,
    provider_entry_id: types.integer,
    identifier: types.maybeNull(types.string),
    name: types.string,
}).views(self => ({

    get nameWithIdentifier() {
        const segments = [self.identifier, self.name].filter(Boolean)

        return segments.join(' - ')
    },

    get sortValue() {
        if (self.identifier) {
            return parseInt(self.identifier)
        }

        return self.name
    },

}))

export default RallySafeEntry
