import { flow, getRoot, types } from "mobx-state-tree"
import ModalState from './models/ModalState'
import Event from './models/Event'
import { callApi } from '../utils'
import { localStorageEventService } from './RootStore'

const EditEvent = types.compose('EventEditModel', Event, types.model({}))

const EventEditStore = types.model('EventEditStore', {

    modalState: types.optional(ModalState, () => ModalState.create()),
    event: types.maybe(EditEvent),

}).actions(self => ({

    create(event) {
        self.modalState.open()
        self.event = event
    },

    edit(event) {
        self.modalState.open()
        self.event = event.serialized
    },

    persist: flow(function* () {
        const body = self.event.serialized
        const { data } = yield callApi({ endpoint: '/events', method: 'POST', body })
        getRoot(self).entityStore.add('events', [data])
        self.reset()

        getRoot(self).setEvent(data.id)
    }),

    save: flow(function* () {
        const body = self.event.serialized
        const { data } = yield callApi({ endpoint: `/events/${self.event.id}`, method: 'PUT', body })
        getRoot(self).entityStore.add('events', [data])
        self.reset()
    }),

    delete: flow(function* () {
        const body = self.event.serialized
        yield callApi({ endpoint: `/events/${self.event.id}`, method: 'DELETE', body })
        self.reset()
        localStorageEventService.reset()
        window.location.reload()
    }),

    reset() {
        self.modalState.close()
        self.event = undefined
    },

})).views(self => ({



}))

export default EventEditStore
