import { types } from "mobx-state-tree"
import RallySafeEntry from './RallySafeEntry'
import Endpoint from '../Endpoint'

export const RALLY_SAFE_PROFILE_ENDPOINT = 'rally_safe_profile'

export const PERSON_MODE_DRIVER = 'driver'
export const PERSON_MODE_CO_DRIVER = 'co_driver'
export const PERSON_MODE_BOTH = 'both'
export const PERSON_MODES = [
    PERSON_MODE_DRIVER,
    PERSON_MODE_CO_DRIVER,
    PERSON_MODE_BOTH,
]

const RallySafeProfileEndpoint = types.model('RallySafeProfileEndpoint', {
    type: types.optional(types.literal(RALLY_SAFE_PROFILE_ENDPOINT), RALLY_SAFE_PROFILE_ENDPOINT),
    entry_id: types.maybe(types.reference(RallySafeEntry)),
    person_mode: types.optional(types.enumeration(PERSON_MODES), PERSON_MODE_DRIVER),
}).actions(self => ({

    setEntryId(value) {
        self.entry_id = value
    },

    setPersonMode(value) {
        self.person_mode = value
    },

})).views(self => ({

    get baseSerialized() {
        return {
            entry_id: self.entry_id?.id,
            person_mode: self.person_mode,
        }
    },

    get baseIsValid() {
        return !!self.entry_id?.id && !!self.person_mode
    },

}))

export default types.compose(Endpoint, RallySafeProfileEndpoint)
