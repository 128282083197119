function Input(props) {
    const { name, label, placeholder, value, className, onSubmit, ...rest } = props

    const handleKeyPress = event => {
        if (event.key === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

    return <div className={`mb-6 ${className}`}>
        <label
            htmlFor={name}
            className='block mb-2 text-sm font-medium text-expo-white'
        >
            {label}
        </label>

        <input
            type='text'
            id={name}
            className="bg-expo-light border border-expo-thin text-expo-white text-sm rounded-lg block w-full p-2.5 text-expo-white focus:ring-0 focus:outline-0 focus:border-expo-white-medium"
            placeholder={placeholder}
            value={value}
            onKeyPress={handleKeyPress}
            {...rest}
            required
        />
    </div>
}

export default Input
