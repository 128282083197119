import { observer } from 'mobx-react-lite'
import Select from '../../Select'
import { useStore } from '../../../state/RootStore'
import {
    PERSON_MODE_BOTH,
    PERSON_MODE_CO_DRIVER,
    PERSON_MODE_DRIVER
} from '../../../state/models/RallySafe/RallySafeProfileEndpoint'
import {listWithNoneOption, parseSelectedInt} from '../../../utils'

function RallySafeProfileEndpointForm({ endpoint, onSubmit }) {
    const rootStore = useStore()
    const { entityStore } = rootStore

    return <div>
        <Select
            label='Entry'
            name='entry'
            options={listWithNoneOption(entityStore.rallySafeEntriesSorted.map(entry => ({
                key: entry.id,
                value: entry.nameWithIdentifier,
            })))}
            value={endpoint.entry_id?.id}
            onSubmit={onSubmit}
            onChange={value => {
                endpoint.setEntryId(parseSelectedInt(value))
            }}
        />

        <Select
            label='Person mode'
            name='person_mode'
            options={[
                { key: PERSON_MODE_DRIVER, value: 'Driver' },
                { key: PERSON_MODE_CO_DRIVER, value: 'Co driver' },
                { key: PERSON_MODE_BOTH, value: 'Both' },
            ]}
            value={endpoint.person_mode}
            onSubmit={onSubmit}
            onChange={value => {
                endpoint.setPersonMode(value)
            }}
        />
    </div>
}

export default observer(RallySafeProfileEndpointForm)
