import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'
import RallySafeCountry from './RallySafeCountry'
import RallySafeClass from './RallySafeClass'
import RallySafeRally from './RallySafeRally'
import RallySafeStage from './RallySafeStage'

export const RALLY_SAFE_SCOREBOARD_ENDPOINT = 'rally_safe_scoreboard'

export const TIME_MODE_PREV = { key: 'prev', value: 'Relative to previous' }
export const TIME_MODE_FIRST = { key: 'first', value: 'Relative to first' }
export const TIME_MODE_START = { key: 'absolute', value: 'Relative to start' }
export const TIME_MODES = {
    [TIME_MODE_PREV.key]: TIME_MODE_PREV,
    [TIME_MODE_FIRST.key]: TIME_MODE_FIRST,
    [TIME_MODE_START.key]: TIME_MODE_START,
}

export const MODES_RESULTS = { key: 'results', value: 'Results' }
export const MODES_RETIREMENTS = { key: 'retirements', value: 'Retirements' }
export const MODES_PENALTIES = { key: 'penalties', value: 'Penalties' }
export const MODES_STARTLISTS = { key: 'startlists', value: 'Start list' }
export const MODES_STAGE_RESULTS = { key: 'stageResults', value: 'Stage results' }
export const MODES = {
    [MODES_RESULTS.key]: MODES_RESULTS,
    [MODES_RETIREMENTS.key]: MODES_RETIREMENTS,
    [MODES_PENALTIES.key]: MODES_PENALTIES,
    [MODES_STARTLISTS.key]: MODES_STARTLISTS,
    [MODES_STAGE_RESULTS.key]: MODES_STAGE_RESULTS,
}

const RallySafeScoreboardEndpoint = types.model('RallySafeScoreboardEndpoint', {
    type: types.optional(types.literal(RALLY_SAFE_SCOREBOARD_ENDPOINT), RALLY_SAFE_SCOREBOARD_ENDPOINT),
    country_id: types.maybe(types.reference(RallySafeCountry)),
    class_id: types.maybe(types.reference(RallySafeClass)),
    rally_id: types.maybe(types.reference(RallySafeRally)),
    stage_id: types.maybe(types.reference(RallySafeStage)),
    page: types.optional(types.integer, 1),
    page_size: types.optional(types.integer, 10),
    pin_first: types.maybe(types.boolean),
    time_mode: types.optional(types.enumeration(Object.keys(TIME_MODES)), TIME_MODE_FIRST.key),
    mode: types.optional(types.enumeration(Object.keys(MODES)), MODES_RESULTS.key),
}).actions(self => ({

    setCountryId(value) {
        self.country_id = value
    },

    setClassId(value) {
        self.class_id = value
    },

    setRallyId(value) {
        self.rally_id = value
    },

    setStageId(value) {
        self.stage_id = value
    },

    setPage(value) {
        self.page = value
    },

    setPageSize(value) {
        self.page_size = value
    },

    setPinFirst(value) {
        self.pin_first = value
    },

    setTimeMode(value) {
        self.time_mode = value
    },

    setMode(value) {
        self.mode = value
    },

})).views(self => ({

    get baseSerialized() {
        return {
            country_id: self.country_id?.id,
            class_id: self.class_id?.id,
            rally_id: self.rally_id?.id,
            stage_id: self.stage_id?.id,
            page: self.page,
            page_size: self.page_size,
            pin_first: self.pin_first || false,
            time_mode: self.time_mode,
            mode: self.mode,
        }
    },

    get baseIsValid() {
        return !!self.page && !!self.page_size && !!self.time_mode && !!self.mode && !!self.rally_id
    },

}))

export default types.compose(Endpoint, RallySafeScoreboardEndpoint)
