import { observer } from 'mobx-react-lite'
import Select from '../../Select'
import { PASSING_POINTS } from '../../../state/models/Ultimate/UltimateEventEndpoint'
import Input from '../../Input'

function UltimateEventEndpointForm({ endpoint, onSubmit }) {
    return <div>
        <Select
            name='passing_point'
            label='Passing count point'
            value={endpoint.passing_point}
            onSubmit={onSubmit}
            onChange={value => {
                endpoint.setPassingPoint(value)
            }}
            options={Object.values(PASSING_POINTS)}
        />

        {/*<Input
            name='Event start time'
            label='Event start time'
            placeholder='hh:mm:ss'
            className='mb-10'
            value={endpoint.event_starts_at}
            onSubmit={onSubmit}
            onChange={e => {
                endpoint.setEventStartsAt(e.target.value)
            }}
        />*/}
    </div>
}

export default observer(UltimateEventEndpointForm)
