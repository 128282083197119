import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'

function UltimateEventEndpointCard({ endpoint }) {
    return <>
        <Line label='Passing count point'>
            <Label>{endpoint.passing_point}</Label>
        </Line>

        {/*<Line label='Event start time'>
            <Label>{endpoint.event_starts_at}</Label>
        </Line>*/}
    </>
}

export default observer(UltimateEventEndpointCard)
