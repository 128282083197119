import { useStore } from '../state/RootStore'
import EndpointCard from './EndpointCard'
import { observer } from 'mobx-react-lite'
import { endpointTypes } from './endpoints/endpointTypes'
import Button from './Button'

const Endpoint = observer(({ endpoint }) => {
    const { card } = endpointTypes[endpoint.type]
    const CardComponent = card

    return <EndpointCard endpoint={endpoint}>
        <CardComponent endpoint={endpoint} />
    </EndpointCard>
})

function Navigation(props) {
    const rootStore = useStore()
    const { endpointEditStore, entityStore } = rootStore
    const { column } = props
    const endpoints = entityStore.endpointsTypeGrouped[column.type] || []

    return <div
        className='gap-5 flex flex-col'
    >
        {endpoints.map(endpoint => {
            return <div key={endpoint.id} onClick={() => {
                endpointEditStore.edit(
                    endpoint.serialized
                )
            }}>
                <Endpoint endpoint={endpoint} />
            </div>
        })}

        <Button
            onClick={() => {
                const { construct } = endpointTypes[column.type] || {}

                if (!construct) {
                    alert('Endpoint type not supported')
                    return
                }

                endpointEditStore.create(
                    construct()
                )
            }}
        >
            Create
        </Button>

    </div>
}

export default observer(Navigation)
