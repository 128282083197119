import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'

function RallySafeProfileEndpointCard({ endpoint }) {
    return <>
        <Line label='Entry'>
            <Label>{endpoint.entry_id?.nameWithIdentifier || '-'}</Label>
        </Line>

        <Line label='Person mode'>
            <Label>{endpoint.person_mode}</Label>
        </Line>
    </>
}

export default observer(RallySafeProfileEndpointCard)
