import EndpointButtons from './EndpointButtons'
import { observer } from 'mobx-react-lite'
import Card from './Card'

function EndpointCard(props) {
    const { endpoint, children } = props

    return <Card>
        <div className='flex justify-between items-center mb-5'>
            <h5 className="text-1xl font-bold tracking-tight text-expo-white">/{endpoint.slug}</h5>

            <EndpointButtons endpoint={endpoint} />
        </div>

        <div className='flex flex-col gap-y-2'>
            {children}
        </div>
    </Card>
}

export default observer(EndpointCard)
