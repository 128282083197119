import { useStore } from '../state/RootStore'
import Navigation from '../components/Navigation'
import { observer } from 'mobx-react-lite'
import EndpointModal from '../components/EndpointModal'
import EventModal from '../components/EventModal'
import Event from '../state/models/Event'
import Select from '../components/Select'
import { values } from 'mobx'
import { useConfirm } from '../utils'
import SourceBadge from '../components/SourceBadge'
import { endpointTypes } from '../components/endpoints/endpointTypes'
import Hr from '../components/Hr'
import Button, { BUTTON_SIZE_MEDIUM, BUTTON_STYLE_DELETE } from '../components/Button'

const EventSelect = observer(() => {
    const rootStore = useStore()
    const { entityStore } = rootStore

    return <Select
        className='min-w-[200px] !mb-0'
        name='event'
        options={values(entityStore.events).map(event => ({
            key: event.id,
            value: event.name,
        }))}
        value={rootStore.selectedEventId}
        onChange={value => {
            rootStore.setEvent(parseInt(value))
        }}
    />
})

function EditEventButton() {
    const rootStore = useStore()
    const { eventEditStore } = rootStore

    return <Button
        onClick={(e) => {
            e.stopPropagation()

            eventEditStore.edit(
                Event.create(rootStore.selectedEvent.serialized)
            )
        }}
        size={BUTTON_SIZE_MEDIUM}
    >Edit</Button>
}

function CreateEventButton() {
    const rootStore = useStore()
    const { eventEditStore } = rootStore

    return <Button onClick={(e) => {
        e.stopPropagation()

        eventEditStore.create({
            id: -1,
            name: '',
            config: {
                mika: {},
                ultimate: {},
                weather_api: {},
                rally_safe: {},
            },
        })
    }} size={BUTTON_SIZE_MEDIUM}>Create</Button>
}

function LogOutButton() {
    const rootStore = useStore()
    const { confirm, isPending } = useConfirm(() => {
        rootStore.logout()
    })

    return <Button
        onClick={(e) => {
            e.stopPropagation()
            confirm()
        }}
        size={BUTTON_SIZE_MEDIUM}
        style={BUTTON_STYLE_DELETE}
    >{isPending ? 'Are you sure?' : 'Log out'}</Button>
}

function DashboardContainer() {
    const rootStore = useStore()
    const { selectedEvent, entityStore } = rootStore

    const columns = Object.values(endpointTypes).filter(type => {
        return selectedEvent && type.isSupported(selectedEvent)
    })

    return <div className=''>
        <div className='flex flex-col'>
            <div className='flex justify-between px-10 py-4'>
                <div className='flex items-center gap-2 justify-center'>
                    <EventSelect />
                    {entityStore.user?.can_configure_events && <>
                        <EditEventButton />
                        <CreateEventButton />
                    </>}
                </div>

                <div className='flex items-center ml-auto'>
                    <LogOutButton />
                </div>
            </div>

            <Hr />

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 p-10'>
                {columns.map(column => {
                    return <div className='flex-1' key={column.type}>
                        <h2 className='text-2xl font-extrabold dark:text-expo-white mb-4'>
                            {column.title}

                            <SourceBadge className='relative -top-3 -left-1'>{column.source}</SourceBadge>
                        </h2>

                        <Navigation column={column} />
                    </div>
                })}
            </div>
        </div>

        <EndpointModal />
        <EventModal />
    </div>
}

export default observer(DashboardContainer)
