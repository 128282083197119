import { observer } from 'mobx-react-lite'
import Checkbox from '../../Checkbox'
import Input from '../../Input'

function UltimateProfileEndpointForm({ endpoint, onSubmit }) {
    return <div>
        <div>
            <Input
                name='start_number'
                label='Start number'
                placeholder='Start number'
                className='mb-10 flex-1'
                value={endpoint.shownStartNumbers}
                onSubmit={onSubmit}
                onChange={e => {
                    endpoint.setStartNumbersFromString(e.target.value)
                }}
            />
        </div>

        <div>
            <Checkbox
                name='show_estimated'
                label='Show estimated time'
                value={endpoint.show_estimated}
                onSubmit={onSubmit}
                onChange={value => {
                    endpoint.setShowEstimated(value)
                }}
            />
        </div>
    </div>
}

export default observer(UltimateProfileEndpointForm)
