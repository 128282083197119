function Line(props) {
    const { label, children } = props

    return <div className="font-normal text-gray-700 dark:text-gray-400 flex items-center flex">
        {label && <div className='mr-2'>{label}</div>}
        {children}
    </div>
}

export default Line
