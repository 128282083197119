import { useStore } from '../state/RootStore'
import Modal from './Modal'
import { observer } from 'mobx-react-lite'
import { values } from 'mobx'
import { endpointTypes } from './endpoints/endpointTypes'
import Input from './Input'
import SourceBadge from './SourceBadge'
import Hr from './Hr'
import Button, {BUTTON_STYLE_SAVE} from './Button'

function EndpointModal() {
    const rootStore = useStore()
    const { endpointEditStore, entityStore } = rootStore
    const { endpoint } = endpointEditStore

    const attemptSubmit = () => {
        if (!endpoint?.isValid) {
            alert('Invalid');

            return
        }

        const duplicate = values(entityStore.endpoints).find(e => {
            return e.slug === endpoint.slug && e.event_id?.id === rootStore.selectedEventId && e.id !== endpoint.id
        })

        if (duplicate) {
            alert('Another endpoint with same slug exists')
            return
        }

        if (endpoint?.id > 0) {
            endpointEditStore.save()
        } else {
            endpointEditStore.persist()
        }
    }

    const formComponent = () => {
        const FormComponent = (endpointTypes[endpoint?.type] || {})?.form

        if (!FormComponent) {
            return null
        }

        return <FormComponent endpoint={endpoint} onSubmit={attemptSubmit} />
    }

    const getEndpointSourceName = () => {
        return endpointTypes[endpoint.type].source
    }

    if (!endpoint) {
        return null
    }

    return <Modal modalState={endpointEditStore.modalState}>
        <div className='p-6 flex flex-col flex-1'>

            <div className='flex justify-between items-center'>
                <h1 className='text-xl font-bold text-white'>{endpoint.id ? 'Edit' : 'Create'} endpoint</h1>

                <div>
                    <SourceBadge className='ml-auto'>{getEndpointSourceName()}</SourceBadge>
                </div>
            </div>

            <Hr spacing />

            <Input
                name='URL'
                label='Endpoint URL *'
                placeholder='For example /weather'
                className='mb-10'
                value={endpoint.slug || ''}
                onSubmit={attemptSubmit}
                onChange={e => {
                    endpoint.setSlug(e.target.value)
                }}
            />

            {formComponent()}

            <div className='w-full flex flex-col'>
                <Button
                    type='button'
                    onClick={attemptSubmit}
                    disabled={!endpoint?.isValid}
                    style={BUTTON_STYLE_SAVE}
                >
                    {endpoint.id > 0 ? 'Save' : 'Create'}
                </Button>
            </div>
        </div>
    </Modal>
}

export default observer(EndpointModal)
