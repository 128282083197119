import { types } from "mobx-state-tree"

const UltimateConfig = types.model('UltimateConfig', {
    configured: false,
    api_key: types.optional(types.string, ''),
    api_user: types.optional(types.string,  ''),
    event_id: types.optional(types.string, ''),
}).actions(self => ({

    setApiKey(value) {
        self.api_key = value
    },

    setApiUser(value) {
        self.api_user = value
    },

    setEventId(value) {
        self.event_id = value
    },

})).views(self => ({

    get serialized() {
        if (!self.isValid) {
            return undefined
        }

        return {
            api_key: self.api_key,
            api_user: self.api_user,
            event_id: self.event_id,
        }
    },

    get isValid() {
        return !!self.api_key && !!self.api_user && !!self.event_id
    },

}))

export default UltimateConfig
