import { useState } from 'react'
import './App.css'
import RootStore, { RootStoreProvider, useStore } from './state/RootStore'
import { useEffect } from 'react'
import DashboardContainer from './containers/DashboardContainer'
import { observer } from 'mobx-react-lite'
import Input from './components/Input'
import Button from './components/Button'

const AppContainer = observer(() => {
    const [token, setToken] = useState()
    const rootStore = useStore()

    const onSubmit = () => {
        rootStore.authenticate(token).catch(() => {
            setToken(undefined)
        })
    }

    if (!rootStore.token) {
        return <div className='w-full h-full flex flex-col justify-center items-center'>
            <h2 className='text-2xl text-center mb-16 text-expo-white'>vmix dashboard</h2>
            <div className='w-[300px] border border-expo-thin rounded-2xl p-4 flex flex-col'>
                <Input
                    name='token'
                    label='Login'
                    placeholder='Token'
                    className='mb-10'
                    value={token || ''}
                    onSubmit={onSubmit}
                    onChange={e => {
                        setToken(e.target.value)
                    }}
                />

                <Button onClick={onSubmit}>Login</Button>

            </div>
        </div>
    }

    return <DashboardContainer />
})

function App() {

    useEffect(() => {
        document.body.className = 'antialiased text-slate-500 dark:text-slate-400 bg-expo-dark';

        RootStore.initialize()
    }, [])

  return (
      <RootStoreProvider value={RootStore}>
          <AppContainer />
      </RootStoreProvider>
  );
}

export default observer(App);
