import { useState } from 'react'
import { useStore } from '../state/RootStore'
import Modal from './Modal'
import { observer } from 'mobx-react-lite'
import Input from './Input'
import Select from './Select'
import { useConfirm } from '../utils'
import Hr from './Hr'
import Button, { BUTTON_STYLE_DELETE, BUTTON_STYLE_SAVE } from './Button'

function Section({ title, isExpanded, setExpanded, children }) {

    return <div className={`border border-expo-thin p-3 pb-0 mb-4 rounded-lg ${isExpanded ? '' : 'hover:bg-expo-light'}`}>
        <h2 className='text-lg font-bold mb-3 cursor-pointer text-expo-white' onClick={() => {
            setExpanded(!isExpanded)
        }}>{title}</h2>

        {isExpanded && <div className='mt-4'>
            {children}
        </div>}
    </div>
}

function DeleteEventButton() {
    const rootStore = useStore()
    const { confirm, isPending } = useConfirm(() => {
        rootStore.eventEditStore.delete()
    })

    return <Button
        onClick={(e) => {
            e.stopPropagation()
            confirm()
        }}
        style={BUTTON_STYLE_DELETE}
    >{isPending ? 'Are you sure?' : 'Delete'}</Button>
}

function EventModal(props) {
    const { eventEditStore } = useStore()
    const { event, modalState } = eventEditStore
    const [expandedForm, setExpandedForm] = useState()

    const attemptSubmit = () => {
        if (!event?.isValid) {
            alert('Form is invalid')

            return
        }

        if (event?.id > -1) {
            eventEditStore.save()
        } else {
            eventEditStore.persist()
        }

        modalState.close()
    }

    const isExpanded = identifier => expandedForm === identifier

    const toggleExpand = identifier => () => {
        setExpandedForm(isExpanded(identifier) ? undefined : identifier)
    }

    if (!event) {
        return null
    }

    return <Modal modalState={eventEditStore.modalState} onClose={() => {
        eventEditStore.reset()
    }}>
        <div className='p-6 flex flex-col flex-1'>

            <Input
                name='name'
                label='Name'
                placeholder='Name'
                value={event.name}
                className='!mb-0'
                onChange={e => {
                    event.setName(e.target.value)
                }}
            />

            <Hr spacing />

            <Section title={'Mika'} isExpanded={isExpanded('mika')} setExpanded={toggleExpand('mika')}>
                <Input
                    name='api_key'
                    label='API key'
                    placeholder='API key'
                    value={event.config.mika.api_key}
                    onChange={e => {
                        event.config.mika.setApiKey(e.target.value)
                    }}
                />

                <Input
                    name='event_key'
                    label='Event key'
                    placeholder='Event key'
                    value={event.config.mika.event_key}
                    onChange={e => {
                        event.config.mika.setEventKey(e.target.value)
                    }}
                />

                <Input
                    name='meeting_id'
                    label='Meeting ID'
                    placeholder='Meeting ID'
                    value={event.config.mika.meeting_id}
                    onChange={e => {
                        event.config.mika.setMeetingId(e.target.value)
                    }}
                />

                <Select
                    name='environment'
                    label='Environment'
                    value={event.config.mika.environment}
                    onChange={value => {
                        event.config.mika.setEnvironment(value)
                    }}
                    options={[
                        { key: 'production', value: 'Production' },
                        { key: 'staging', value: 'Staging' },
                    ]}
                />
            </Section>


            <Section title={'Ultimate'} isExpanded={isExpanded('ultimate')} setExpanded={toggleExpand('ultimate')}>
                <Input
                    name='api_key'
                    label='API key'
                    placeholder='API key'
                    value={event.config.ultimate.api_key}
                    onChange={e => {
                        event.config.ultimate.setApiKey(e.target.value)
                    }}
                />

                <Input
                    name='api_user'
                    label='API user'
                    placeholder='API user'
                    value={event.config.ultimate.api_user}
                    onChange={e => {
                        event.config.ultimate.setApiUser(e.target.value)
                    }}
                />

                <Input
                    name='event_id'
                    label='Event ID'
                    placeholder='Event ID'
                    value={event.config.ultimate.event_id}
                    onChange={e => {
                        event.config.ultimate.setEventId(e.target.value)
                    }}
                />
            </Section>

            <Section title={'Weather'} isExpanded={isExpanded('weather_api')} setExpanded={toggleExpand('weather_api')}>
                <Input
                    name='api_key'
                    label='API key'
                    placeholder='API key'
                    value={event.config.weather_api.api_key}
                    onChange={e => {
                        event.config.weather_api.setApiKey(e.target.value)
                    }}
                />
            </Section>

            <Section title={'Rally'} isExpanded={isExpanded('rally_safe')} setExpanded={toggleExpand('rally_safe')}>
                <Input
                    name='event_id'
                    label='Event ID'
                    placeholder='Event ID'
                    value={event.config.rally_safe.event_id}
                    onChange={e => {
                        event.config.rally_safe.setEventId(e.target.value)
                    }}
                />

                <Input
                    name='itinerary_id'
                    label='Itinerary ID'
                    placeholder='Itinerary ID'
                    value={event.config.rally_safe.itinerary_id}
                    onChange={e => {
                        event.config.rally_safe.setItineraryId(e.target.value)
                    }}
                />

                <Input
                    name='username'
                    label='Username'
                    placeholder='Username'
                    value={event.config.rally_safe.username}
                    onChange={e => {
                        event.config.rally_safe.setUsername(e.target.value)
                    }}
                />

                <Input
                    name='password'
                    label='Password'
                    placeholder='Password'
                    value={event.config.rally_safe.password}
                    onChange={e => {
                        event.config.rally_safe.setPassword(e.target.value)
                    }}
                />
            </Section>

            <div className='mt-auto w-full flex flex-col'>
                <Button
                    onClick={attemptSubmit}
                    disabled={!event?.isValid}
                    style={BUTTON_STYLE_SAVE}
                >
                    {event?.id > -1 ? 'Save' : 'Create'}
                </Button>

                {event?.id > -1 && <DeleteEventButton />}
            </div>
        </div>
    </Modal>
}

export default observer(EventModal)
