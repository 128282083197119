import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'
import { GENDERS } from '../../../state/models/Ultimate/UltimateLeaderboardEndpoint'

function UltimateLeaderboardEndpointCard({ endpoint }) {
    return <>
        <Line label='Page'>
            <Label>{endpoint.page}</Label>
        </Line>

        <Line label='Page size'>
            <Label>{endpoint.page_size}</Label>
        </Line>

        <Line label='Gender'>
            <Label>{GENDERS[endpoint.gender]?.value || 'All'}</Label>
        </Line>

        <Line label='Nationality'>
            <Label>{endpoint.nationality_id?.name || 'All'}</Label>
        </Line>

        <Line label='Split'>
            <Label>{endpoint.split_time_id?.name}</Label>
        </Line>

        <Line label='Pin first entry'>
            <Label>{endpoint.pin_first ? 'Yes' : 'No'}</Label>
        </Line>

        <Line label='DM'>
            <Label>{endpoint.dm_filter ? 'Yes' : 'No'}</Label>
        </Line>

        <Line label='Category'>
            <Label>{endpoint.category_id?.name || 'All'}</Label>
        </Line>
    </>
}

export default observer(UltimateLeaderboardEndpointCard)
