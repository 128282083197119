import { flow, getRoot, types } from "mobx-state-tree"
import ModalState from './models/ModalState'
import { EndpointUnion } from './EntityStore'
import { callApi } from '../utils'

const EndpointEditStore = types.model('EndpointEditStore', {

    modalState: types.optional(ModalState, () => ModalState.create()),
    endpoint: types.maybe(EndpointUnion),

}).actions(self => ({

    create(endpoint) {
        self.modalState.open()
        self.endpoint = endpoint
    },

    edit(endpoint) {
        self.modalState.open()
        self.endpoint = endpoint
    },

    persist: flow(function* () {
        const body = self.endpoint.serialized
        const { data } = yield callApi({ endpoint: `/events/${self.event.id}/endpoints`, method: 'POST', body })
        getRoot(self).entityStore.add('endpoints', [data])
        self.reset()
    }),

    save: flow(function* () {
        const body = self.endpoint.serialized
        const { data } = yield callApi({ endpoint: `/endpoints/${self.endpoint.id}`, method: 'PATCH', body })
        getRoot(self).entityStore.add('endpoints', [data])
        self.reset()
    }),

    reset() {
        self.modalState.close()
        self.endpoint = undefined
    },

})).views(self => ({

    get event() {
        return getRoot(self).selectedEvent
    }

}))

export default EndpointEditStore
