import WeatherApiEndpoint, { WEATHER_API_ENDPOINT } from '../../state/models/WeatherApi/WeatherApiEndpoint'
import RallySafeProfileEndpoint, { RALLY_SAFE_PROFILE_ENDPOINT } from '../../state/models/RallySafe/RallySafeProfileEndpoint'
import WeatherApiEndpointCard from './cards/WeatherApiEndpointCard'
import RallySafeProfileEndpointCard from './cards/RallySafeProfileEndpointCard'
import WeatherApiEndpointForm from './forms/WeatherApiEndpointForm'
import RallySafeProfileEndpointForm from './forms/RallySafeProfileEndpointForm'
import RallySafeScoreboardEndpoint, { RALLY_SAFE_SCOREBOARD_ENDPOINT } from '../../state/models/RallySafe/RallySafeScoreboardEndpoint'
import RallySafeScoreboardEndpointCard from './cards/RallySafeScoreboardEndpointCard'
import RallySafeScoreboardEndpointForm from './forms/RallySafeScoreboardEndpointForm'
import UltimateEventEndpoint, { ULTIMATE_EVENT_ENDPOINT } from '../../state/models/Ultimate/UltimateEventEndpoint'
import UltimateEventEndpointCard from './cards/UltimateEventEndpointCard'
import UltimateEventEndpointForm from './forms/UltimateEventEndpointForm'
import UltimateProfileEndpoint, { ULTIMATE_PROFILE_ENDPOINT } from '../../state/models/Ultimate/UltimateProfileEndpoint'
import UltimateProfileEndpointCard from './cards/UltimateProfileEndpointCard'
import UltimateProfileEndpointForm from './forms/UltimateProfileEndpointForm'
import UltimateLeaderboardEndpoint, { ULTIMATE_LEADERBOARD_ENDPOINT } from '../../state/models/Ultimate/UltimateLeaderboardEndpoint'
import UltimateLeaderboardEndpointCard from './cards/UltimateLeaderboardEndpointCard'
import UltimateLeaderboardEndpointForm from './forms/UltimateLeaderboardEndpointForm'
import MikaProfileEndpoint, { MIKA_PROFILE_ENDPOINT } from '../../state/models/Mika/MikaProfileEndpoint'
import MikaProfileEndpointCard from './cards/MikaProfileEndpointCard'
import MikaProfileEndpointForm from './forms/MikaProfileEndpointForm'
import MikaLeaderboardEndpoint, { MIKA_LEADERBOARD_ENDPOINT } from '../../state/models/Mika/MikaLeaderboardEndpoint'
import MikaLeaderboardEndpointCard from './cards/MikaLeaderboardEndpointCard'
import MikaLeaderboardEndpointForm from './forms/MikaLeaderboardEndpointForm'

export const endpointTypes = {
    [WEATHER_API_ENDPOINT]: {
        type: WEATHER_API_ENDPOINT,
        card: WeatherApiEndpointCard,
        form: WeatherApiEndpointForm,
        construct: () => WeatherApiEndpoint.create(),
        isSupported: event => !!event.config.weather_api.configured,
        title: 'Weather',
        source: 'Weather API',
    },
    [RALLY_SAFE_PROFILE_ENDPOINT]: {
        type: RALLY_SAFE_PROFILE_ENDPOINT,
        card: RallySafeProfileEndpointCard,
        form: RallySafeProfileEndpointForm,
        construct: () => RallySafeProfileEndpoint.create(),
        isSupported: event => !!event.config.rally_safe.configured,
        title: 'Profile',
        source: 'Rally Safe',
    },
    [RALLY_SAFE_SCOREBOARD_ENDPOINT]: {
        type: RALLY_SAFE_SCOREBOARD_ENDPOINT,
        card: RallySafeScoreboardEndpointCard,
        form: RallySafeScoreboardEndpointForm,
        construct: () => RallySafeScoreboardEndpoint.create(),
        isSupported: event => !!event.config.rally_safe.configured,
        title: 'Scoreboard',
        source: 'Rally Safe',
    },
    [ULTIMATE_EVENT_ENDPOINT]: {
        type: ULTIMATE_EVENT_ENDPOINT,
        card: UltimateEventEndpointCard,
        form: UltimateEventEndpointForm,
        construct: () => UltimateEventEndpoint.create(),
        isSupported: event => !!event.config.ultimate.configured,
        title: 'Event',
        source: 'Ultimate',
    },
    [ULTIMATE_PROFILE_ENDPOINT]: {
        type: ULTIMATE_PROFILE_ENDPOINT,
        card: UltimateProfileEndpointCard,
        form: UltimateProfileEndpointForm,
        construct: () => UltimateProfileEndpoint.create(),
        isSupported: event => !!event.config.ultimate.configured,
        title: 'Profile',
        source: 'Ultimate',
    },
    [ULTIMATE_LEADERBOARD_ENDPOINT]: {
        type: ULTIMATE_LEADERBOARD_ENDPOINT,
        card: UltimateLeaderboardEndpointCard,
        form: UltimateLeaderboardEndpointForm,
        construct: () => UltimateLeaderboardEndpoint.create(),
        isSupported: event => !!event.config.ultimate.configured,
        title: 'Leaderboard',
        source: 'Ultimate',
    },
    [MIKA_PROFILE_ENDPOINT]: {
        type: MIKA_PROFILE_ENDPOINT,
        card: MikaProfileEndpointCard,
        form: MikaProfileEndpointForm,
        construct: () => MikaProfileEndpoint.create(),
        isSupported: event => !!event.config.mika.configured,
        title: 'Profile',
        source: 'Mika',
    },
    [MIKA_LEADERBOARD_ENDPOINT]: {
        type: MIKA_LEADERBOARD_ENDPOINT,
        card: MikaLeaderboardEndpointCard,
        form: MikaLeaderboardEndpointForm,
        construct: () => MikaLeaderboardEndpoint.create(),
        isSupported: event => !!event.config.mika.configured,
        title: 'Leaderboard',
        source: 'Mika',
    },
}
