import { useContext, createContext } from 'react'
import { flow, types } from "mobx-state-tree"
import EntityStore from './EntityStore'
import { callApi } from '../utils'
import EndpointEditStore from './EndpointEditStore'
import EventEditStore from './EventEditStore'
import { endpointTypes } from '../components/endpoints/endpointTypes'
import { values } from 'mobx'
import { first } from 'lodash'

export const localStorageEventService = {
    get: () => localStorage.getItem('event_id'),
    set: (id) => localStorage.setItem('event_id', id),
    has: () => !!localStorage.getItem('event_id'),
    reset: () => localStorage.removeItem('event_id'),
}

export const tokenService = {
    get: () => localStorage.getItem('vmix_token'),
    set: (token) => localStorage.setItem('vmix_token', token),
    has: () => !!localStorage.getItem('vmix_token'),
    reset: () => localStorage.removeItem('vmix_token'),
}

const RootStore = types.model('RootStore', {

    entityStore: types.optional(EntityStore, () => EntityStore.create({})),
    endpointEditStore: types.optional(EndpointEditStore, () => EndpointEditStore.create()),
    eventEditStore: types.optional(EventEditStore, () => EventEditStore.create()),
    selectedEventId: types.maybe(types.integer),
    token: types.maybe(types.maybeNull(types.string)),

}).actions(self => ({

    initialize: flow(function* () {
        if (!self.isAuthenticated) {
            return
        }

        yield self.loadMe()
        yield self.loadEvents()

        if (!self.entityStore.events.has(localStorageEventService.get())) {
            localStorageEventService.reset()
            yield self.setEvent(first(values(self.entityStore.events))?.id)
        } else {
            yield self.initializeEvent()
        }
    }),

    initializeEvent: flow(function* () {

        self.entityStore.clear('endpoints')
        self.entityStore.clear('mikaNationalities')
        self.entityStore.clear('mikaSplitTimes')
        self.entityStore.clear('ultimateNationalities')
        self.entityStore.clear('ultimateSplitTimes')
        self.entityStore.clear('ultimateCategories')
        self.entityStore.clear('rallySafeEntries')

        yield self.loadEntities()
        yield self.loadEndpoints()
    }),

    setEvent: flow(function* (eventId) {
        if (eventId) {
            self.selectedEventId = eventId
            localStorageEventService.set(eventId)

            yield self.initializeEvent()
        }
    }),

    loadEvents: flow(function* () {
        const { data } = yield callApi({ endpoint: '/events' })

        self.entityStore.add('events', data)

        if (!localStorageEventService.has() && data.length > 0) {
            localStorageEventService.set(data[0].id)
        }
    }),

    loadEndpoints: flow(function* () {
        const { data } = yield callApi({ endpoint: `/events/${self.selectedEventId}/endpoints` })

        self.entityStore.add('endpoints', data.filter(endpoint => Object.keys(endpointTypes).includes(endpoint.type)))
    }),

    deleteEndpoint: flow(function* (endpoint) {
        yield callApi({ endpoint: `/endpoints/${endpoint.id}`, method: 'delete' })

        self.entityStore.endpoints.delete(endpoint.id)
    }),

    loadMe: flow(function* () {
        const { data } = yield callApi({ endpoint: '/me' })

        self.entityStore.set('user', data)
    }),

    loadEntities: flow(function* () {
        const { data } = yield callApi({ endpoint: `/entities?events=${self.selectedEventId}` })

        const {
            ultimateNationalities,
            ultimateSplitTimes,
            ultimateCategories,

            mikaNationalities,
            mikaSplitTimes,

            rallySafeEntries,
            rallySafeCountries,
            rallySafeClasses,
            rallySafeRallies,
            rallySafeStages,
        } = data

        self.entityStore.add('mikaNationalities', mikaNationalities, true)
        self.entityStore.add('mikaSplitTimes', mikaSplitTimes, true)

        self.entityStore.add('ultimateNationalities', ultimateNationalities, true)
        self.entityStore.add('ultimateSplitTimes', ultimateSplitTimes, true)
        self.entityStore.add('ultimateCategories', ultimateCategories, true)

        self.entityStore.add('rallySafeEntries', rallySafeEntries, true)
        self.entityStore.add('rallySafeCountries', rallySafeCountries, true)
        self.entityStore.add('rallySafeClasses', rallySafeClasses, true)
        self.entityStore.add('rallySafeRallies', rallySafeRallies, true)
        self.entityStore.add('rallySafeStages', rallySafeStages, true)
    }),

    authenticate: flow(function* (value) {
        if (value) {
            yield callApi({ endpoint: `/ping`, token: value })

            tokenService.set(value)
            self.token = value
            self.initialize()
        }
    }),

    logout() {
        tokenService.reset()
        self.token = null
    },

})).views(self => ({

    get selectedEvent() {
        return self.entityStore.events.get(self.selectedEventId)
    },

    get isAuthenticated() {
        return !!self.token
    },

})).create({
    selectedEventId: localStorageEventService.has() ? parseInt(localStorageEventService.get()) : undefined,
    token: tokenService.get(),
})

const RootStoreContext = createContext(null)
export const RootStoreProvider = RootStoreContext.Provider

export function useStore() {
    const store = useContext(RootStoreContext)

    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider')
    }

    return store
}

export default RootStore
