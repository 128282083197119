import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'

function UltimateProfileEndpointCard({ endpoint }) {
    return <Line label='Start numbers'>
        <Label>{endpoint.shownStartNumbers}</Label>
    </Line>
}

export default observer(UltimateProfileEndpointCard)
