import { types } from "mobx-state-tree"

const WeatherApi = types.model('WeatherApi', {
    configured: false,
    api_key: types.optional(types.string, ''),
}).actions(self => ({

    setApiKey(value) {
        self.api_key = value
    },

})).views(self => ({

    get serialized() {
        if (!self.isValid) {
            return undefined
        }

        return {
            api_key: self.api_key,
        }
    },

    get isValid() {
        return !!self.api_key
    },

}))

export default WeatherApi
