import { types } from "mobx-state-tree"
import Endpoint from '../Endpoint'

export const MIKA_PROFILE_ENDPOINT = 'mika_profile'

const MikaProfileEndpoint = types.model('MikaProfileEndpoint', {
    type: types.optional(types.literal(MIKA_PROFILE_ENDPOINT), MIKA_PROFILE_ENDPOINT),
    start_numbers: types.optional(types.maybe(types.maybeNull(types.array(types.string))), []),
    participant_ids: types.maybe(types.maybeNull(types.array(types.string))),
}).actions(self => ({

    serializeStringToArray(value) {
        if (!value) {
            return null
        }

        const result = [...value].filter(Boolean)
        return result.length === 0 ? null : result
    },

    setStartNumbersFromString(value) {
        self.start_numbers = value.split(',')
    },

    enableStartNumbers() {
        self.start_numbers = []
        self.participant_ids = null
    },

    setParticipantIdsFromString(value) {
        self.participant_ids = value.split(',')
    },

    enableParticipantIds() {
        self.participant_ids = []
        self.start_numbers = null
    },

})).views(self => ({

    get baseSerialized() {
        return {
            start_numbers: self.serializeStringToArray(self.start_numbers),
            participant_ids: self.serializeStringToArray(self.participant_ids),
        }
    },

    get baseIsValid() {
        return self.start_numbers?.length > 0 || self.participant_ids?.length > 0
    },

    get shownStartNumbers() {
        return self.start_numbers?.join(',')
    },

    get shownParticipantIds() {
        return self.participant_ids?.join(',')
    },

}))

export default types.compose(Endpoint, MikaProfileEndpoint)
