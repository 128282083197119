import { types } from "mobx-state-tree"
import { groupBy, keyBy } from 'lodash'
import Event from './models/Event'
import { values } from 'mobx'
import WeatherApiEndpoint from './models/WeatherApi/WeatherApiEndpoint'
import UltimateNationality from './models/Ultimate/UltimateNationality'
import UltimateSplitTime from './models/Ultimate/UltimateSplitTime'
import MikaNationality from './models/Mika/MikaNationality'
import MikaSplitTime from './models/Mika/MikaSplitTime'
import RallySafeEntry from './models/RallySafe/RallySafeEntry'
import RallySafeProfileEndpoint from './models/RallySafe/RallySafeProfileEndpoint'
import RallySafeCountry from './models/RallySafe/RallySafeCountry'
import RallySafeClass from './models/RallySafe/RallySafeClass'
import RallySafeScoreboardEndpoint from './models/RallySafe/RallySafeScoreboardEndpoint'
import RallySafeRally from './models/RallySafe/RallySafeRally'
import _ from 'lodash'
import RallySafeStage from './models/RallySafe/RallySafeStage'
import UltimateEventEndpoint from './models/Ultimate/UltimateEventEndpoint'
import UltimateProfileEndpoint from './models/Ultimate/UltimateProfileEndpoint'
import UltimateLeaderboardEndpoint from './models/Ultimate/UltimateLeaderboardEndpoint'
import MikaProfileEndpoint from './models/Mika/MikaProfileEndpoint'
import MikaLeaderboardEndpoint from './models/Mika/MikaLeaderboardEndpoint'
import User from './models/User'
import UltimateCategory from './models/Ultimate/UltimateCategory'

export const EndpointUnion = types.union(
    WeatherApiEndpoint,
    RallySafeProfileEndpoint,
    RallySafeScoreboardEndpoint,

    UltimateEventEndpoint,
    UltimateProfileEndpoint,
    UltimateLeaderboardEndpoint,

    MikaProfileEndpoint,
    MikaLeaderboardEndpoint,
)

const EntityStore = types.model('EntityStore', {
    user: types.maybe(User),
    endpoints: types.map(EndpointUnion),
    events: types.map(Event),

    // sources
    mikaNationalities: types.map(MikaNationality),
    mikaSplitTimes: types.map(MikaSplitTime),

    ultimateNationalities: types.map(UltimateNationality),
    ultimateSplitTimes: types.map(UltimateSplitTime),
    ultimateCategories: types.map(UltimateCategory),

    rallySafeEntries: types.map(RallySafeEntry),
    rallySafeCountries: types.map(RallySafeCountry),
    rallySafeClasses: types.map(RallySafeClass),
    rallySafeRallies: types.map(RallySafeRally),
    rallySafeStages: types.map(RallySafeStage),

}).actions(self => ({

    push(attr, values) {
        self[attr] = [
            ...self[attr],
            ...values,
        ]
    },

    add(entity, entities, clear = false) {
        const identifiers = []

        if (clear) {
            self[entity].clear()
        }

        if (!entities) {
            return []
        }

        self[entity].merge(
            keyBy(entities, e => {
                identifiers.push(e.id)

                return e.id
            }),
        )

        return identifiers
    },

    set(attr, value) {
        self[attr] = value
    },

    clear(entity) {
        self[entity].clear()
    },

})).views(self => ({


    get endpointsTypeGrouped() {
        return groupBy(values(self.endpoints), endpoint => endpoint.type)
    },

    get rallySafeEntriesSorted() {
        return _.sortBy(
            values(self.rallySafeEntries),
            entry => entry.sortValue,
        )
    },

}))

export default EntityStore
