import { types } from "mobx-state-tree"

const MikaConfig = types.model('MikaConfig', {
    configured: false,
    api_key: types.optional(types.string, ''),
    environment: types.optional(types.string, ''),
    event_key: types.optional(types.string, ''),
    meeting_id: types.optional(types.string, ''),
}).actions(self => ({

    setApiKey(value) {
        self.api_key = value
    },

    setEnvironment(value) {
        self.environment = value
    },

    setEventKey(value) {
        self.event_key = value
    },

    setMeetingId(value) {
        self.meeting_id = value
    },

})).views(self => ({

    get serialized() {
        if (!self.isValid) {
            return undefined
        }

        return {
            api_key: self.api_key,
            environment: self.environment,
            event_key: self.event_key,
            meeting_id: self.meeting_id,
        }
    },

    get isValid() {
        return !!self.api_key && !!self.environment && !!self.event_key && !!self.meeting_id
    },

}))

export default MikaConfig
