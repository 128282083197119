import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'

function MikaProfileEndpointCard({ endpoint }) {
    return <>
        {endpoint.shownStartNumbers && <Line label='Start numbers'>
            <Label>{endpoint.shownStartNumbers}</Label>
        </Line>}

        {endpoint.shownParticipantIds && <Line label='Participant ids'>
            <Label>{endpoint.shownParticipantIds}</Label>
        </Line>}
    </>
}

export default observer(MikaProfileEndpointCard)
