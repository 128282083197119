import { observer } from 'mobx-react-lite'
import Line from '../../Line'
import Label from '../../Label'

function WeatherApiEndpointCard({ endpoint }) {
    return <Line label='Search query'>
        <Label>{endpoint.search_query}</Label>
    </Line>
}

export default observer(WeatherApiEndpointCard)
