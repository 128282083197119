import { resolveEndpoint, useConfirm } from '../utils'
import { useStore } from '../state/RootStore'
import Button, {BUTTON_SIZE_SMALL, BUTTON_STYLE_DELETE} from './Button'

function OpenEndpointButton({ endpoint }) {
    const url = resolveEndpoint(`/endpoints/${endpoint.event_id.id}/${endpoint.slug}`)

    return <Button
        onClick={(e) => {
            e.stopPropagation()
            window.open(url, '_blank');
        }}
        size={BUTTON_SIZE_SMALL}
    >Open</Button>
}

function DeleteEndpointButton({ endpoint }) {
    const rootStore = useStore()
    const { confirm, isPending } = useConfirm(() => {
        rootStore.deleteEndpoint(endpoint)
    })

    return <Button
        onClick={(e) => {
            e.stopPropagation()
            confirm()
        }}
        size={BUTTON_SIZE_SMALL}
        style={BUTTON_STYLE_DELETE}
    >{isPending ? 'Are you sure?' : 'Delete'}</Button>
}

function EndpointButtons(props) {
    const { endpoint } = props

    return <div className='flex gap-1'>
        <DeleteEndpointButton endpoint={endpoint} />
        <OpenEndpointButton endpoint={endpoint} />
    </div>
}

export default EndpointButtons
